import {
  CarrierStatusCode,
  EnumPaymentStatus,
  EnumPaymentType,
  EnumPlatforms,
  EnumPreOrderStatusCode,
  EnumStateCode,
  EnumStatusFrenet,
  ImportedOrderDraftStatusCode,
  LegalEntityType,
} from '.';
import { theme } from '../styles/theme';

const EnumStatesCodeAndPaymentGroup = [
  {
    value: 1,
    label: 'Pendente Pagamento',
    color: '#fdc62d',
    variant: 'orange',
    statusGroup: [
      {
        status: EnumStateCode.Draft,
        payment_status: EnumPaymentStatus.None,
      },
      {
        status: EnumStateCode.Draft,
        payment_status: EnumPaymentStatus.Expired,
      },
      {
        status: EnumStateCode.Draft,
        payment_status: EnumPaymentStatus.Canceled,
      },
    ],
  },
  {
    value: 2,
    label: 'Pagamento em Análise',
    variant: 'orange',
    color: '#fdc62d',
    statusGroup: [
      {
        status: EnumStateCode.Draft,
        payment_status: EnumPaymentStatus.Pending,
      },
    ],
  },
  {
    value: 3,
    label: 'Pagamento Rejeitado',
    variant: 'danger',
    color: '#fa5f7e',
    statusGroup: [
      {
        status: EnumStateCode.Draft,
        payment_status: EnumPaymentStatus.Rejected,
      },
    ],
  },
  {
    value: 4,
    label: 'Pagamento Expirado',
    variant: 'danger',
    color: '#fa5f7e',
    statusGroup: [
      {
        status: EnumStateCode.Canceled,
        payment_status: EnumPaymentStatus.Expired,
      },
      {
        status: EnumStateCode.Expired,
        payment_status: EnumPaymentStatus.Expired,
      },
      {
        status: EnumStateCode.Expired,
        payment_status: EnumPaymentStatus.None,
      },
    ],
  },
  {
    value: 5,
    label: 'Cancelado',
    variant: 'danger',
    color: '#fa5f7e',
    statusGroup: [
      {
        status: EnumStateCode.Canceled,
        payment_status: EnumPaymentStatus.Canceled,
      },
      {
        status: EnumStateCode.Canceled,
        payment_status: EnumPaymentStatus.Pending,
      },
      {
        status: EnumStateCode.Canceled,
        payment_status: EnumPaymentStatus.Rejected,
      },
      {
        status: EnumStateCode.Canceled,
        payment_status: EnumPaymentStatus.None,
      },
    ],
  },
  {
    value: 6,
    label: 'Aguardando Drop',
    variant: 'orange',
    color: '#fdc62d',
    statusGroup: [
      {
        status: EnumStateCode.Ready,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 7,
    label: 'Expirado',
    variant: 'danger',
    color: '#fa5f7e',
    disable: true,
    statusGroup: [
      {
        status: EnumStateCode.Expired,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 8,
    label: 'Estornado',
    variant: 'danger',
    color: '#fa5f7e',
    statusGroup: [
      {
        status: EnumStateCode.Canceled,
        payment_status: EnumPaymentStatus.Reversal,
      },
      {
        status: EnumStateCode.Draft,
        payment_status: EnumPaymentStatus.Reversal,
      },
    ],
  },
  {
    value: 9,
    label: 'Dropado',
    variant: 'secondary',
    color: '#26b68e',
    statusGroup: [
      {
        status: EnumStateCode.Dropped,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 10,
    label: 'Em Entrega',
    variant: 'secondary',
    color: '#26b68e',
    statusGroup: [
      {
        status: EnumStateCode.InTransit,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 11,
    label: 'Entregue',
    variant: 'secondary',
    color: '#26b68e',
    statusGroup: [
      {
        status: EnumStateCode.Delivered,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 12,
    label: 'Coletado Transportadora',
    variant: 'secondary',
    color: '#26b68e',
    statusGroup: [
      {
        status: EnumStateCode.CollectedByCarrier,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 13,
    label: 'A coletar',
    variant: 'secondary',
    color: '#26b68e',
    disable: true,
    statusGroup: [
      {
        status: EnumStateCode.ToCollect,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 14,
    label: 'Devolução Dropada',
    variant: 'secondary',
    color: '#26b68e',
    statusGroup: [
      {
        status: EnumStateCode.DevolutionDropped,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
  {
    value: 15,
    label: 'Encomenda Devolvida',
    variant: 'secondary',
    color: '#26b68e',
    statusGroup: [
      {
        status: EnumStateCode.OrderReturned,
        payment_status: EnumPaymentStatus.Approved,
      },
    ],
  },
];

const EnumOptionsPayment = [
  { label: 'Pagamento em análise', value: EnumPaymentStatus.Pending },
  { label: 'Pagamento Pendente', value: EnumPaymentStatus.None },
  { label: 'Pagamento Aprovado', value: EnumPaymentStatus.Approved },
  { label: 'Pagamento Cancelado', value: EnumPaymentStatus.Canceled },
  { label: 'Pagamento Rejeitado', value: EnumPaymentStatus.Rejected },
  { label: 'Pagamento Estornado', value: EnumPaymentStatus.Reversal },
  { label: 'Pagamento Expirado', value: EnumPaymentStatus.Expired },
];

const EnumPaymentMethod = [
  { label: 'Pix', value: EnumPaymentType.PIX },
  { label: 'Cartão de Crédito', value: EnumPaymentType.CreditCard },
];

const EnumOptionsLegalEntityType = [
  { label: 'EPP', value: LegalEntityType.EPP },
  { label: 'ME', value: LegalEntityType.ME },
  { label: 'MEi', value: LegalEntityType.MEI },
  { label: 'Não Optante', value: LegalEntityType.None },
];

const EnumOptionsCarrierStatusCode = [
  { label: 'Vazio', value: CarrierStatusCode.None },
  { label: 'Solicitado', value: CarrierStatusCode.Requested },
  { label: 'Em Progresso', value: CarrierStatusCode.InProgress },
  { label: 'Entregue', value: CarrierStatusCode.Completed },
  { label: 'Coletado', value: CarrierStatusCode.Collected },
  { label: 'Cancelado', value: CarrierStatusCode.Canceled },
];

const EnumStatusPreOrderLabel = [
  {
    label: 'Efetivado',
    value: EnumPreOrderStatusCode.Finished,
    color: 'secondary',
  },
  {
    label: 'Cancelado',
    value: EnumPreOrderStatusCode.Canceled,
    color: 'danger',
    disable: true,
  },
  {
    label: 'Integrado',
    value: EnumPreOrderStatusCode.Integrated,
    color: 'grey',
  },
  {
    label: 'Não Processado',
    value: EnumPreOrderStatusCode.NotProcessed,
    color: 'orange',
  },
  {
    label: 'Em Processamento',
    value: EnumPreOrderStatusCode.Processing,
    color: 'primary',
  },
];

const EnumPlatformsLabel = [
  {
    label: 'Nuvemshop',
    value: EnumPlatforms.Nuvemshop,
  },
  {
    label: 'Drops',
    value: EnumPlatforms.Drops,
  },
];

const ImportedOrderDraftStatusCodeLabel = [
  {
    label: 'Importado',
    value: ImportedOrderDraftStatusCode.Imported,
    color: 'gray',
  },
  {
    label: 'Em processamento',
    value: ImportedOrderDraftStatusCode.Processing,
    color: 'primary',
  },
  {
    label: 'Não Processado',
    value: ImportedOrderDraftStatusCode.NotProcessed,
    color: 'danger',
  },
  {
    label: 'Efetivado',
    value: ImportedOrderDraftStatusCode.Finished,
    color: 'secondary',
  },
  {
    label: 'Cancelado',
    value: ImportedOrderDraftStatusCode.Canceled,
    color: 'danger',
  },
];

const EnumPlatformsImg = [
  {
    code: EnumPlatforms.Nuvemshop,
    value:
      'https://stpudoprd.blob.core.windows.net/public/assets/drops/integrations/icons/nuvemshop.png',
  },
];

const EnumStatusFrenetOptions = [
  {
    label: 'Desconhecido',
    value: EnumStatusFrenet.Unknown,
    color: theme.colors.dangerColor,
  },
  {
    label: 'Criado',
    value: EnumStatusFrenet.Criado,
    color: theme.colors.primaryColor,
  },
  {
    label: 'Pagamento Pendente',
    value: EnumStatusFrenet.PendentePagamento,
    color: theme.colors.primaryColor,
  },
  {
    label: 'Falha no Pagamento',
    value: EnumStatusFrenet.FalhaPagamento,
    color: theme.colors.dangerColor,
  },
  {
    label: 'Pago',
    value: EnumStatusFrenet.SucessoPagamento,
    color: theme.colors.secondaryColor,
  },
  {
    label: 'Postado',
    value: EnumStatusFrenet.Postado,
    color: theme.colors.secondaryColor,
  },
  {
    label: 'Cancelamento Agendado',
    value: EnumStatusFrenet.CancelamentoAgendado,
    color: theme.colors.dangerColor,
  },
  {
    label: 'Cancelado',
    value: EnumStatusFrenet.Cancelado,
    color: theme.colors.dangerColor,
  },
  {
    label: 'Excluído',
    value: EnumStatusFrenet.Excluido,
    color: theme.colors.dangerColor,
  },
];

export {
  EnumOptionsPayment,
  EnumStatesCodeAndPaymentGroup,
  EnumPaymentMethod,
  EnumOptionsLegalEntityType,
  EnumOptionsCarrierStatusCode,
  EnumStatusPreOrderLabel,
  EnumPlatformsLabel,
  EnumPlatformsImg,
  ImportedOrderDraftStatusCodeLabel,
  EnumStatusFrenetOptions,
};
