import styled, { css } from 'styled-components';
import { LeftSidebarProps } from '.';

export const Container = styled.div<LeftSidebarProps>`
  ${({ theme, active }) => css`
    color: ${theme.colors.primaryColor};
    font-size: ${theme.font.sizes.mediumXX};
    font-weight: ${theme.font.fontWeight.semiBold};
    width: 260px;
    z-index: 10;
    background: #fff;
    bottom: 0;
    position: fixed;
    top: 0;
    padding: 70px 0 0 0;
    margin-top: 1em;
    box-shadow: 1px 1px 0.2rem rgba(0, 0, 0, 0.2);
    display: ${active ? 'block' : 'none'};
    @media (max-width: 1023px) {
      width: 100%;
    }

    ul {
      margin: 0 15px;

      svg {
        margin-right: 8px;
      }
    }
  `}
`;

export const LiSidebar = styled.li`
  ${({ theme }) => css`
    padding: 15px 0;
    border-bottom: 1px solid #00000029;

    a {
      color: ${theme.colors.primaryColor};
      :hover {
        font-weight: ${theme.font.fontWeight.semiBold};
        color: ${theme.colors.secondaryColor};
      }
    }

    .active-nav {
      font-weight: ${theme.font.fontWeight.semiBold};
      color: ${theme.colors.secondaryColor};
    }
  `}
`;

export const UlSub = styled.ul`
  ${({ theme }) => css`
    margin: 0 10px !important;
    padding: 0.1em !important;
    font-size: ${theme.font.sizes.mediumX};
    font-weight: ${theme.font.fontWeight.medium};
    li {
      padding: 5px 25px;
      border-bottom: none;
    }
  `}
`;
