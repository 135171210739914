import HttpCarriersService from '../../config/HttpCarriers';
import { GetCarrierResponse, GetDimensionsResponse } from './carriers.types';

export class CarriersService {
  api: HttpCarriersService;

  constructor() {
    this.api = new HttpCarriersService();
  }

  getCarrier = (id: string) => {
    return this.api.get(`v1/carriers/${id}`);
  };

  getCarriers = (): Promise<GetCarrierResponse> => {
    return this.api.get(`v1/carriers/`);
  };

  getDimensions = (): Promise<GetDimensionsResponse> => {
    return this.api.get(`v1/carriers/carrier_dimensions?default_carrier=true`);
  };
}
