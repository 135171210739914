import { AxiosResponse } from 'axios';
import HttpPostalCodeService from '../../config/HttpPostalCode';
import { GetPostalCodeResponse } from './postalCode.types';

export class PostalCodeService {
  api: HttpPostalCodeService;

  constructor() {
    this.api = new HttpPostalCodeService();
  }

  getPostalCode = (
    search: string
  ): Promise<AxiosResponse<GetPostalCodeResponse>> => {
    return this.api.getFunction(
      `get-address-postal-code?address_or_postal_code=${search}`
    );
  };

  getAddress = (code: string) => {
    return this.api.getFunction(`get-postalcode?postal_code=${code}`);
  };
}
