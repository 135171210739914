import axios from 'axios';
import { Environment, getCurrentApiEnv } from '../../config/Environment';
import { getToken } from '../../config/GetToken';

export default class HttpPagSeguroService {
  service: any;

  constructor() {
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.service.defaults.headers.common.Authorization = getToken();
  }

  get() {
    this.service.defaults.headers = {
      ...this.service.defaults.headers,
      'x-functions-key':
        Environment.baseUrl[getCurrentApiEnv()].functionPagSeguro.code,
    };

    const { url } = Environment.baseUrl[getCurrentApiEnv()].functionPagSeguro;
    return this.service.get(`${url}get-session-id`);
  }

  getPixExpiration(id: string) {
    this.service.defaults.headers = {
      ...this.service.defaults.headers,
      'x-functions-key':
        Environment.baseUrl[getCurrentApiEnv()].functionPagSeguro.code,
    };

    const { url } = Environment.baseUrl[getCurrentApiEnv()].functionPagSeguro;
    return this.service.get(`${url}get-pix-data-from-user-interaction/${id}`);
  }
}
