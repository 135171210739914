import { Dimensions } from '../service/shared/carriers.types';

export const convertedMeasuresToCm = (measures: Dimensions) => {
  return {
    max_height: measures.package_max_length_mm / 10,
    max_length: measures.package_max_width_mm / 10,
    max_width: measures.package_max_height_mm / 10,
    max_weight: measures.package_max_weight_g / 1000,
    dimensions_sum: measures.package_max_dimensions_sum_mm / 10,
  };
};
