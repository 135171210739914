enum EnumAlert {
  Dange = 1,
  Warning = 2,
  Success = 3,
  Info = 4,
}

enum EnumServiceType {
  Reverse = 1,
  Delivery = 2,
}

enum EnumPaymentStatus {
  None = 0,
  Pending = 1,
  Approved = 2,
  Canceled = 3,
  Rejected = 4,
  Reversal = 5,
  Expired = 6,
}

enum VoucherValidationError {
  Expired = 1,
  ClientDocument = 2,
  ClientState = 3,
  UseLimitCount = 4,
  Inactive = 5,
}

enum EnumScenario {
  Scenario1 = 1, // tem transportadora
  Scenario2 = 2, // não tem transportadora
  Scenario3 = 3,
  Scenario4 = 4,
  Scenario5 = 5,
}

enum EnumStateCode {
  Draft = 1,
  Ready = 2,
  Canceled = 3,
  Expired = 4,
  Dropped = 5,
  InTransit = 6,
  Delivered = 7,
  ToCollect = 8,
  CollectedByCarrier = 9,
  DevolutionDropped = 10,
  OrderReturned = 11,
}

enum EnumEvent {
  Create = 1,
  DroppedByClient = 2,
  DeliveredToCarrier = 3,
  DeliveredToRecipient = 4,
  PaymentApproved = 5,
  CarrierDeliveredToRecipient = 6,
  SellerLabelSentByEmail = 7,
  BarcodeSentByEmail = 8,
  CarrierRequested = 9,
  StuckInDropped = 10,
  StuckInReady = 11,
  PaymentCanceledBeforeDrop = 12,
  DroppedByCarrier = 13,
  UpdatePaymentStatus = 14,
  CarrierInTransit = 15,
  OrderCanceled = 16,
  DevolutionDropped = 18,
  OrderReturned = 19,
}

enum EnumPaymentType {
  None = 0,
  PaymentSlip = 1,
  BankTransfer = 2,
  CreditCard = 3,
  PIX = 4,
}

enum LegalEntityType {
  ME = 1,
  EPP = 2,
  MEI = 3,
  None = 4,
}

enum EnumDocumentType {
  Invoice = 1,
  Declaration = 2,
}

enum CarrierStatusCode {
  None = 1,
  Requested = 2,
  InProgress = 3,
  Completed = 4,
  Collected = 5,
  Canceled = 6,
}

enum OrderType {
  Default = 1,
  PreOrder = 2,
  ImportedOrderDraft = 3,
}

enum ActorType {
  Sender = 1,
  Receiver = 2,
}

enum AddressComponentType {
  PostalCode = 'postal_code',
  Line = 'route',
  District = 'sublocality_level_1',
  City = 'administrative_area_level_2',
  State = 'administrative_area_level_1',
  Country = 'country',
}

const RouteUrl = {
  Requests: '/requests',
  OrdersCart: '/orders-cart',
  SimulateDelivery: '/simulate-delivery',
  OrdersPayment: '/order-payment',
  OrdersPixPayment: '/order-payment-pix',
  OrdersRequest: '/order-request',
  OrdersDetails: '/requests/order-details/:id',
  Profile: '/profile',
  Integration: '/integrations',
  effectivePreRegister: '/effective-pre-register',
  PreOrders: '/pre-orders',
  ShippingQuotesPreOrder: '/pre-orders-shipping-quotes',
  PackagePreOrder: '/pre-orders/packages',
  ShippingPoint: '/shipping-point',
  SimulateBatch: '/simulate-batch',
  SimulateBatchShippingQuotes: '/simulate-batch/shipping-quotes',
  LabelHTML: '/label-page',
  ClientRegistration: '/client/registration',
  DefaultPackage: '/profile/default-package',
  ShopperRegistration: '/profile/shopper-registration',
  ShopperDetails: '/profile/shopper-registration/registration',
  RegisterIntegration: '/register-integration/:integration',
  Wallet: '/wallet',
  Extract: '/extract',
  MaintenancePage: '/maintenance-page',
  FAQ: '/faq',
  Conciliation: '/conciliation',
};

enum EnumPreOrderStatusCode {
  Integrated = 1,
  Finished = 2,
  Canceled = 3,
  Processing = 4,
  NotProcessed = 5,
}

enum EnumPlatforms {
  Drops = 'drops',
  Nuvemshop = 'nuvemshop',
}

enum ImportedOrderDraftStatusCode {
  Imported = 1,
  Processing = 2,
  NotProcessed = 3,
  Finished = 4,
  Canceled = 5,
}

enum PlatformType {
  PudoApp = 1,
  Seller2 = 2,
}

enum UserLoginType {
  BusinessUnitId = 1,
  ClientId = 2,
  MarketplaceId = 3,
  UserId = 4,
}

enum EnumStatusFrenet {
  Unknown = 0,
  Criado = 1,
  PendentePagamento = 2,
  FalhaPagamento = 3,
  SucessoPagamento = 4,
  Postado = 5,
  CancelamentoAgendado = 6,
  Cancelado = 7,
  Excluido = 9,
}

enum EnumVerificationCode {
  Email = 1,
  Phone = 2,
}

export {
  EnumAlert,
  EnumServiceType,
  EnumPaymentStatus,
  VoucherValidationError,
  RouteUrl,
  EnumScenario,
  EnumStateCode,
  EnumEvent,
  LegalEntityType,
  EnumDocumentType,
  EnumPaymentType,
  CarrierStatusCode,
  EnumPreOrderStatusCode,
  EnumPlatforms,
  OrderType,
  ImportedOrderDraftStatusCode,
  ActorType,
  AddressComponentType,
  PlatformType,
  UserLoginType,
  EnumStatusFrenet,
  EnumVerificationCode,
};
