import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeOrdersProps } from '../pages/orders/orderCart/@types';

export type OrderDetails = {
  selectedOrder?: TypeOrdersProps;
};

export type TypeOrderDetails = {
  orderDetails: OrderDetails;
};

const initialState: OrderDetails = {
  selectedOrder: undefined,
};

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    updateSelectedDetails: (state, action: PayloadAction<OrderDetails>) => {
      return action.payload;
    },
  },
});

export const { updateSelectedDetails } = orderDetailsSlice.actions;

export const selectOrderDetails = (state: TypeOrderDetails) => {
  return state.orderDetails;
};

export default orderDetailsSlice.reducer;
