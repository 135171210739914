export async function downloadFileBlob(
  data: any,
  mimeType: string,
  extensao: string,
  nameFile: string
) {
  if (!data) return false;
  if (!mimeType) return false;
  if (!extensao) return false;

  try {
    const blob = new Blob([data], { type: mimeType });
    const element = document.createElement('a');
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.setAttribute('download', `${nameFile}.${extensao}`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    return true;
  } catch (error) {
    return false;
  }
}
