import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const TextModal = styled.p`
  font-size: 14px;
  color: white;
  line-height: 1.5rem;
  padding: 0 10px;
  margin: auto;
`;

export const ToolTipValidated = styled.button`
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 10000;
  height: 40px;
  outline: none;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.dangerColor};
`;
