import { CONST_LOCALSTORAGE } from '../constants/localStorage';
import { RemoveWebchatZendesk } from './webchatZendesk';

function logoutFunction() {
  localStorage.removeItem(CONST_LOCALSTORAGE.TOKEN);
  localStorage.removeItem(CONST_LOCALSTORAGE.CART_INFORMATION);
  localStorage.removeItem(CONST_LOCALSTORAGE.PAYMENTS_ORDERS);
  sessionStorage.clear();
  RemoveWebchatZendesk();
}
export { logoutFunction };
