import jwt from 'jwt-decode';

import { compareDesc, fromUnixTime } from 'date-fns';
import { CONST_LOCALSTORAGE } from '../constants/localStorage';
import { logoutFunction } from '../utils/logout';

type JwtProps = {
  exp: number;
};

export const getToken = () => {
  const token = localStorage.getItem(CONST_LOCALSTORAGE.TOKEN);

  if (token) {
    let isExpired = false;

    const decodedToken: JwtProps = jwt(token);

    const dateNow = new Date().getTime();

    const expDate = decodedToken ? decodedToken.exp : 0;

    if (compareDesc(fromUnixTime(expDate), dateNow) === 1) isExpired = true;

    if (isExpired) {
      logoutFunction();
      window.location.href = `${window.location.origin}`;
    } else {
      return `Bearer ${token}`;
    }
  } else {
    logoutFunction();
    window.location.href = `${window.location.origin}`;
  }
  return null;
};
