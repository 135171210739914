import { EnumPaymentStatus, EnumStateCode } from '../enum';
import {
  EnumPlatformsLabel,
  EnumStatesCodeAndPaymentGroup,
  EnumStatusFrenetOptions,
} from '../enum/optionsEnum';
import { StatusCodePayment, StatusGroup } from '../types/StatusCodePayment';

const getStatusLabelFrenet = (paymentStatus: number) => {
  return EnumStatusFrenetOptions.find((e) => {
    return e.value === paymentStatus;
  });
};

const getStatusLabel = (statusCode: number, paymentStatus: number) => {
  return EnumStatesCodeAndPaymentGroup.find((e) => {
    return e.statusGroup.find(
      (x) => x.status === statusCode && x.payment_status === paymentStatus
    );
  });
};

interface IValidPrinting {
  statusCode?: number;
  paymentStatus?: number;
  statusLabel?: any;
}

const validPrintingStatus = (props: IValidPrinting) => {
  let status;

  if (props.statusCode && props.paymentStatus)
    status = getStatusLabel(props.statusCode, props.paymentStatus);

  if (props.statusLabel) status = props.statusLabel;

  if (
    status?.statusGroup[0].payment_status === EnumPaymentStatus.Approved &&
    status?.statusGroup[0].status !== EnumStateCode.Expired &&
    status?.statusGroup[0].status !== EnumStateCode.Canceled
  )
    return true;

  return false;
};

const getStatusActiveCodeAndPaymentGroup = () => {
  return EnumStatesCodeAndPaymentGroup.filter((element) => !element.disable);
};

const getStatusLabelSorted = () => {
  return getStatusActiveCodeAndPaymentGroup().sort((a: any, b: any) => {
    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
  });
};
const getPlataformsLabelSorted = () => {
  return EnumPlatformsLabel.sort((a, b) => {
    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
  });
};

const getStatusFilter = (status: StatusCodePayment[]) => {
  let statusFilter: StatusGroup[] = [];
  status.forEach((element: StatusCodePayment) => {
    statusFilter = [...statusFilter, ...element.statusGroup];
  });
  return statusFilter;
};

const getStatusFilterAll = () => {
  let statusFilter: StatusGroup[] = [];
  EnumStatesCodeAndPaymentGroup.forEach((element: StatusCodePayment) => {
    if (!element.disable)
      statusFilter = [...statusFilter, ...element.statusGroup];
  });
  return statusFilter;
};

export {
  getStatusLabel,
  getStatusLabelSorted,
  getPlataformsLabelSorted,
  getStatusFilter,
  getStatusFilterAll,
  validPrintingStatus,
  getStatusLabelFrenet,
};
