import { createGlobalStyle } from 'styled-components';
/* eslint-disable global-require */

export const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-family: ${({ theme }) => theme.font.family.default};
}
a {
  text-decoration: none;
}
ul {
  padding-left: 0;
}
li {
  list-style-type: none
}
p {
  line-height: 2em;
}
hr {
 color: #BFC7CE;
}

.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding-top: 90px;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.backgroundPageColor};
}
.page-container {
  min-height: calc(100vh - 293px);
  padding: 10px 30px;
  position: relative;
}
.menu-active {
  margin-left: 0px;
}
.line-md-vertical {
  @media (min-width: 768px){
    border-left: 1px solid #bcd7d1;
    border-right: 1px solid #bcd7d1;
  }
}
.line-lg-vertical {
  @media (min-width: 992px){
    border-left: 1px solid #bcd7d1;
    border-right: 1px solid #bcd7d1;
  }
}
.divider{
  border-bottom: 1px solid #8e9ca8;
}

.card {
  border: none;
}
.card-content {
  margin: 5px;
  padding: 20px;
  background: #f5f7f8;
  border-radius: 7px;
}
.line-horizontal {
  border-top: 1px solid #bcd7d1;
  border-bottom: 1px solid #bcd7d1;
}

.tooltip > .tooltip-inner {
  background-color: #FFF;
  color:  ${({ theme }) => theme.colors.primaryColor};
  border-color: #FFF;
  box-shadow: 1px 1px 0.2rem rgb(0 0 0 / 20%);
}

.tooltip > div.tooltip-arrow::before {
  border-bottom-color: #FFF;
  border-top-color: #FFF;
  border-left-color: #FFF;
  border-right-color: #FFF;
}

.pagination-default {
    display: flex;
    padding-left: 0;
    list-style: none;
    li {
      border: solid 1px #FFF;
      border-radius: 30px;
      background-color: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: #fff;
      margin: 0 5px 0 5px;
      cursor: pointer;
      a {
        color: #313a46;
        text-decoration: none;
        border: none;
      }
    }
    li:hover {
      border: solid 1px #eef2f7;
      border-radius: 30px;
    }
  }

  .active-pagination {
    border: ${({ theme }) => `solid 1px  ${theme.colors.secondaryColor}`};
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.secondaryColor} !important;
    a {
      color: #fff !important;
    }
  }

  .disabled-pagination {
    display: none !important;
  }

  .disabled-pagination-link {
    pointer-events: none !important;
    cursor: default !important;
    text-decoration: none !important;
  }

// Conteiner PlaceAutocomplet
  .pac-container {
    margin-top: 5px;
    border-radius: 5px;
    padding-left: 2px;
  }

  .pac-item {
    border: none;
    margin-bottom: 2px;
    cursor: pointer;
    padding: 2px 0 0 5px;
    font-size: ${({ theme }) => theme.font.sizes.medium};
    font-family: ${({ theme }) => theme.font.family.default};
  }

  .pac-item-query {
    font-size: ${({ theme }) => theme.font.sizes.medium};
    font-family: ${({ theme }) => theme.font.family.default};
  }

// Font Weight

.font-weight-light {
  font-weight: ${({ theme }) => theme.font.fontWeight.light};
}
.font-weight-medium {
  font-weight: ${({ theme }) => theme.font.fontWeight.medium};
}

//
// Saira fonts
//

// Saira-Light
@font-face {
  font-family: 'Saira';
  src: local('Saira Light'), local('Saira-Light'),
    url(${require('../assets/fonts/saira/Saira-Light.ttf')}) format('truetype');
  font-weight: 300;
  font-style: normal;
}

// Saira-Regular
@font-face {
  font-family: 'Saira';
  src: local('Saira Regular'), local('Saira-Regular'),
    url(${require('../assets/fonts/saira/Saira-Regular.ttf')}) format('truetype');
  font-weight: 400;
  font-style: normal;
}

// Saira-Medium
@font-face {
  font-family: 'Saira';
  src: local('Saira Medium'), local('Saira-Medium'),
    url(${require('../assets/fonts/saira/Saira-Medium.ttf')}) format('truetype');
  font-weight: 500;
  font-style: normal;
}

// Saira-SemiBold
@font-face {
  font-family: 'Saira';
  src: local('Saira SemiBold'), local('Saira-SemiBold'),
    url(${require('../assets/fonts/saira/Saira-SemiBold.ttf')}) format('truetype');
  font-weight: 600;
  font-style: normal;
}

// Saira-Bold
@font-face {
  font-family: 'Saira';
  src: local('Saira Bold'), local('Saira-Bold'),
    url(${require('../assets/fonts/saira/Saira-Bold.ttf')}) format('truetype');
  font-weight: 700;
  font-style: normal;
}

`;
