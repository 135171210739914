import { BrowserRouter } from 'react-router-dom';
import { PrivateRoutes } from './routes';

function Routes() {
  return (
    <BrowserRouter>
      <PrivateRoutes />
    </BrowserRouter>
  );
}

export default Routes;
