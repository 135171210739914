import styled, { css } from 'styled-components';
import { theme } from '../../styles/theme';
import ImgBuilding from '../../assets/images/building.png';

const Loader = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: ${theme.colors.primaryColor};
  padding: 20px;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 14em;
  height: auto;
  margin: 56px 0;
`;

const Middle = styled.div`
  ${({ theme }) => css`
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    p {
      color: ${theme.colors.lemonColor};
      font-size: 20px;
      margin: 14px 0;
    }
    .progress {
      max-width: 600px;
      height: 8px;
      margin: auto;
      background-color: transparent;
      border: 1px solid ${theme.colors.secondaryColor};
      .progress-bar {
        background-color: ${theme.colors.secondaryColor};
        flex-direction: row;
        justify-content: end;
        svg {
          margin-top: -32px;
          position: absolute;
          transform: rotateY(180deg);
        }
      }
    }
  `}
`;

const BuildingBackground = styled.div`
  position: absolute;
  bottom: 0;
  background: url(${ImgBuilding}) repeat-x;
  height: 300px;
  width: 5076px;
  animation: anima 20s linear 0s 1 normal forwards;
  @keyframes anima {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-300px, 0, 0);
    }
  }
`;

export { Loader, Logo, Middle, BuildingBackground };
