import axios from 'axios';
import { UserLoginType } from '../enum';
import { Environment, getCurrentApiEnv } from './Environment';

export default class HttpRegistrationService {
  service: any;

  constructor() {
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'X-User-Login-Type': UserLoginType.ClientId,
        'x-functions-key':
          Environment.baseUrl[getCurrentApiEnv()].funcClientRegistration.code,
      },
      validateStatus: (status) => status >= 200 && status <= 302,
    });
  }

  get(path: string) {
    const url =
      Environment.baseUrl[getCurrentApiEnv()].funcClientRegistration.url + path;
    return this.service.get(url);
  }

  put(path: string, payload: any) {
    const url =
      Environment.baseUrl[getCurrentApiEnv()].funcClientRegistration.url + path;
    return this.service.put(url, payload);
  }

  post(path: string, payload: any) {
    const url =
      Environment.baseUrl[getCurrentApiEnv()].funcClientRegistration.url + path;
    return this.service.post(url, payload);
  }

  delete(path: string) {
    const url =
      Environment.baseUrl[getCurrentApiEnv()].funcClientRegistration.url + path;
    return this.service.delete(url);
  }
}
