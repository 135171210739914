// @flow
import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import SignalR from './components/SignalR';
import Routes from './routes';
import { GlobalStyles } from './styles/globalStyles';
import { theme } from './styles/theme';

function App(): ReactElement {
  const { isAuthenticated } = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes />
      {isAuthenticated && <SignalR />}
    </ThemeProvider>
  );
}

export default App;
