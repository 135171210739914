/**
 * Configuração das rotas do APP para comunicação com a API
 */

export const getCurrentApiEnv = () => {
  if (process.env.REACT_APP_ENV === 'local') return 'local';
  if (process.env.REACT_APP_ENV === 'production') return 'production';
  if (process.env.REACT_APP_ENV === 'NewDevelop') return 'NewDevelop';
  if (process.env.REACT_APP_ENV === 'NewHomolog') return 'NewHomolog';
  return 'NewDevelop';
};

export const isIndicateDropsPoint = () => {
  return process.env.REACT_APP_INDICATE_DROPS
    ? process.env.REACT_APP_INDICATE_DROPS === 'true'
    : false;
};

export const Environment = {
  baseUrl: {
    local: {
      maintenance: {
        url: 'https://func-drops-sellermaintenance-dev.azurewebsites.net/api/',
        apiKey: 'kO9qFZxh2DkRBf6LdApaecLxE8N1NJe8nNi2rnG03P55AzFuGe1ETg==',
      },
      apiKeyMercadoPagoFrenet: 'TEST-e67cf6c0-8570-4a43-8518-292aa3dac28a',
      urlApi: 'https://app-pudo-clients-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-pudo-orders-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-dev.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-pudo-businessunits-api-dev.azurewebsites.net/api/',
      urlMarketingActions:
        'https://app-pudo-marketing-actions-api-dev.azurewebsites.net/api/',
      funcClientRegistration: {
        url: 'https://func-pudo-client-registration-dev.azurewebsites.net/api/',
        code: 'QVGrzLIFx0thv9EF_mK6l1l4Dx1YcEOz_Jb_YeL5Bq0kAzFuSeazAA==',
        codeRecommendation:
          '1b7TbbkAUwIznoScIrBuyPR1QGbym1eVY5qrKA9tYEtIAzFu4G4x4Q==',
      },
      funcPostalCode: {
        url: 'https://func-pudo-postalcode-dev.azurewebsites.net/api/',
        code: 'euDmMqf1aZFz4PzV7coboOzrzCZzWtmOPlobkPIpXjSsj0AQMTJarg==',
      },
      functionPagSeguro: {
        url: 'https://func-pudo-payment-processor-dev.azurewebsites.net/api/',
        code: 'g_h2B3s65l78XFK7GhmrQWAExsPhiam26F0iVlEfxWqEAzFuO9X4IA==',
      },
      funcSignalR: {
        url: 'https://func-pudo-shared-signalr-dev.azurewebsites.net/api/',
        code: 'DvemEw0mOzSvUqwpdxfNUkTF5jk5iiPnggM01cG-TE-vAzFuI37U3Q==',
      },
      funcCnpj: {
        url: 'https://func-pudo-shared-actors-dev.azurewebsites.net/api/',
        code: '2KSPtXOyqfyrsV1b168pazrEDHQC-ba5Ens3f2eq7r09AzFurf_p2w==',
      },
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'URjGZwxs18EBboWSZjrmyLAm5RPnZpfQ',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/clients-api',
      recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      googleMaps: {
        url: 'https://maps.googleapis.com/maps/',
        key: 'AIzaSyBv3uMHZQIdOHXbCoSlDlcetT7Zr1NZAx0',
      },
      funcNumvemshop: {
        code: 'eebpck0fHxW2dmMbnJTRebR-hpJoQ1tG9leqfIiAsVzjAzFuZ5mMTQ==',
        url: 'https://func-pudo-orders-nuvemshop-dev.azurewebsites.net/api/',
      },
      resources: {
        simulateBatch: true,
        integration: true,
      },
    },
    NewDevelop: {
      maintenance: {
        url: 'https://func-drops-sellermaintenance-dev.azurewebsites.net/api/',
        apiKey: 'kO9qFZxh2DkRBf6LdApaecLxE8N1NJe8nNi2rnG03P55AzFuGe1ETg==',
      },
      apiKeyMercadoPagoFrenet: 'TEST-e67cf6c0-8570-4a43-8518-292aa3dac28a',
      urlApi: 'https://app-drops-clients-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-dev.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-drops-businessunits-api-dev.azurewebsites.net/api/',
      urlMarketingActions:
        'https://app-drops-marketing-actions-api-dev.azurewebsites.net/api/',
      funcClientRegistration: {
        url: 'https://func-drops-clients-registration-dev.azurewebsites.net/api/',
        code: 'QVGrzLIFx0thv9EF_mK6l1l4Dx1YcEOz_Jb_YeL5Bq0kAzFuSeazAA==',
        codeRecommendation:
          '-OxfWxla6JAUpr8jJNcUtT3PJ2eQuubiAztPA2fOWNNjAzFuwxvTHQ==',
      },
      funcPostalCode: {
        url: 'https://func-drops-postal-code-dev.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      functionPagSeguro: {
        url: 'https://func-drops-processors-payment-dev.azurewebsites.net/api/',
        code: 'aJ24MS4-Y5BkSFFBq3UT3xrfZQnNUFxtIv6S272c-YQlAzFupVNlOw==',
      },
      funcSignalR: {
        url: 'https://func-drops-shared-service-signalr-dev.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      funcCnpj: {
        url: 'https://func-drops-shared-actor-dev.azurewebsites.net/api/',
        code: 'Sx4_dErfnmOykBrU0HxKGThZbIS4LYEAkZrvjAQMX2tuAzFu-4MSJw==',
      },
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'URjGZwxs18EBboWSZjrmyLAm5RPnZpfQ',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/clients-api',
      recaptchaSiteKey: '6LcpizwgAAAAAP0iIehLE7zk8VrwtcnLibaoSTqi',
      googleMaps: {
        url: 'https://maps.googleapis.com/maps/',
        key: 'AIzaSyBv3uMHZQIdOHXbCoSlDlcetT7Zr1NZAx0',
      },
      funcNumvemshop: {
        code: 'rpGIpSmwZD6e-i4e1rMwydJVgMEO9dWrutm-VS2eYUVbAzFu5U35aw==',
        url: 'https://func-drops-order-nuvemshop-dev.azurewebsites.net/api/',
      },
      resources: {
        simulateBatch: true,
        integration: true,
      },
    },
    NewHomolog: {
      maintenance: {
        url: 'https://func-drops-sellermaintenance-dev.azurewebsites.net/api/',
        apiKey: 'kO9qFZxh2DkRBf6LdApaecLxE8N1NJe8nNi2rnG03P55AzFuGe1ETg==',
      },
      apiKeyMercadoPagoFrenet: 'TEST-e67cf6c0-8570-4a43-8518-292aa3dac28a',
      urlApi: 'https://app-drops-clients-api-hml.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-hml.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-hml.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-hml.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-drops-businessunits-api-hml.azurewebsites.net/api/',
      urlMarketingActions:
        'https://app-drops-marketing-actions-api-hml.azurewebsites.net/api/',
      funcClientRegistration: {
        url: 'https://func-drops-clients-registration-hml.azurewebsites.net/api/',
        code: 'o9f5zBb03vZbGzFeUCvU14abWn9urpD6wKBb7SNuOVJDAzFuqoUyfw==',
        codeRecommendation:
          '-OxfWxla6JAUpr8jJNcUtT3PJ2eQuubiAztPA2fOWNNjAzFuwxvTHQ==',
      },
      funcPostalCode: {
        url: 'https://func-drops-postal-code-hml.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      functionPagSeguro: {
        url: 'https://func-drops-processors-payment-hml.azurewebsites.net/api/',
        code: 'aJ24MS4-Y5BkSFFBq3UT3xrfZQnNUFxtIv6S272c-YQlAzFupVNlOw==',
      },
      funcSignalR: {
        url: 'https://func-drops-shared-service-signalr-hml.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      funcCnpj: {
        url: 'https://func-drops-shared-actor-hml.azurewebsites.net/api/',
        code: 'Sx4_dErfnmOykBrU0HxKGThZbIS4LYEAkZrvjAQMX2tuAzFu-4MSJw==',
      },
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'NMmljF7JeXmrJ10GpCo9L2d0T8ZITdqo',
      auth0Domain: 'login-hml.pontodrops.com.br',
      auth0Audience: 'https://apis/clients-api',
      recaptchaSiteKey: '6LcpizwgAAAAAP0iIehLE7zk8VrwtcnLibaoSTqi',
      googleMaps: {
        url: 'https://maps.googleapis.com/maps/',
        key: 'AIzaSyDFFUaaMqa1ruVv35yZZrVRN9a3iEs6zig',
      },
      funcNumvemshop: {
        code: 'rpGIpSmwZD6e-i4e1rMwydJVgMEO9dWrutm-VS2eYUVbAzFu5U35aw==',
        url: 'https://func-drops-order-nuvemshop-hml.azurewebsites.net/api/',
      },
      resources: {
        simulateBatch: true,
        integration: true,
      },
    },
    production: {
      maintenance: {
        url: 'https://func-drops-sellermaintenance-prd.azurewebsites.net/api/',
        apiKey: 'JmkJCo_faCuuRUU0d6zqHboDqWbV2kujybNzgdUHl4V9AzFueBQKuw==',
      },
      apiKeyMercadoPagoFrenet: 'APP_USR-b3a1e456-5bd6-4f53-9b0b-9c3555bc26f3',
      urlApi: 'https://clients-api.pontodrops.com.br/api/',
      urlCarriers: 'https://carriers-api.pontodrops.com.br/api/',
      urlOrders: 'https://orders-api.pontodrops.com.br/api/',
      urlPayments: 'https://payments-api.pontodrops.com.br/api/',
      urlBusinessUnits: 'https://businessunits-api.pontodrops.com.br/api/',
      urlMarketingActions:
        'https://marketing-actions-api.pontodrops.com.br/api/',
      funcClientRegistration: {
        url: 'https://client-registration.pontodrops.com.br/api/',
        code: 'ifHbr1GK4wSyqUGSkWOU-lkVWSXado9ufuu5CIIITRpXAzFudUEHFA==',
        codeRecommendation:
          'LfNvOLiInXJ2ytLA2EFVVaH_71zcrMMajtczvH46SYFDAzFuje9SVA==',
      },
      funcPostalCode: {
        url: 'https://postalcode.pontodrops.com.br/api/',
        code: 'wik0dMBik0BQX8V39QTUpqRMyzVUd1HcqS29MUWDQkJI1l1AbeVs3w==',
      },
      functionPagSeguro: {
        url: 'https://payment-processor.pontodrops.com.br/api/',
        code: '4I_Pu6rsKnYnxflpWPor0y10xGtM6GoQtOmZgxjf47VhAzFu8Dq06Q==',
      },
      funcSignalR: {
        url: 'https://signalr.pontodrops.com.br/api/',
        code: 't2fgh6TJCAVkE_bzNc5gwpOZgatjhTku2DAUe43HRqOZAzFuxOZ7bQ==',
      },
      funcCnpj: {
        url: 'https://shared-actors.pontodrops.com.br/api/',
        code: 'p3nHq8EHm2g86Jiyw1BkZGJN8-rs1PCaBkKwnsqmvuaVAzFuDCN2tQ==',
      },
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'UiUSvsy5uRSPchXNoxsKdT8BrZKfCR1Y',
      auth0Domain: 'login.pontodrops.com.br',
      auth0Audience: 'https://apis/clients-api',
      recaptchaSiteKey: '6LcpizwgAAAAAP0iIehLE7zk8VrwtcnLibaoSTqi',
      googleMaps: {
        url: 'https://maps.googleapis.com/maps/',
        key: 'AIzaSyAMQowMFD0dC4yastYsNCdlM8Se9_vX27M',
      },
      funcNumvemshop: {
        code: 'UF1nDsAT3JXpTiU0CDfb7qKNMJe3xN6Uva-DSOP_i5UvAzFux9QLeQ==',
        url: 'https://orders-nuvemshop.pontodrops.com.br/api/',
      },
      resources: {
        simulateBatch: false,
        integration: false,
      },
    },
  },
};
