import { ReactElement } from 'react';
import { Paths } from './pathD';

type IconProps = {
  name: string;
  className?: string;
  color?: string;
  size?: number;
  viewBox?: string;
  onClick?: () => void;
};

function Icon({
  name,
  color = '#FFF',
  size = 25,
  viewBox = '0 0 25 25',
  className,
  onClick,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size}
      height={size}
      onClick={onClick}
      className={className}
    >
      <g fill={color}>
        <path
          width={size}
          height={size}
          d={Paths.find((x) => x.name === name)?.value || ''}
        />
      </g>
    </svg>
  );
}

export default Icon;
