import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';

export const HeaderModal = styled(Modal.Header)`
  display: flex;
  padding: 18px 18px 0px 30px;
`;

export const TitleModal = styled.h4`
  font-size: 18px;
  color: #03ad75 !important;

  text-align: center;
  width: 100%;

  @media (min-width: 576px) {
    font-size: 24px;
    text-align: left;
  }
`;

export const CloseButton = styled.button`
  display: none;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  border-radius: 15px;
  border: none;
  background-color: #8e9ca8;

  color: #fff;
  font-size: 20px;

  @media (min-width: 576px) {
    display: flex;

    width: 50px;
    height: 50px;

    font-size: 30px;
    border-radius: 25px;

    position: absolute;
    top: -24px;
    right: -12px;
  }
`;

export const BodyContainer = styled(Modal.Body)`
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-size: 14px;
  color: #004d59;
  line-height: 1.5rem;

  ul {
    padding-left: 15px;
  }

  p {
    margin: 0;
    padding: 0 10px;
  }
`;
