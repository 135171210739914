import { AxiosResponse } from 'axios';

import {
  GetExtractResponse,
  GetWalletResponse,
  PostDepositResponse,
} from './wallet.types';
import HttpClientPayments from '../../config/HttpClientPayments';

export class WalletService {
  api: HttpClientPayments;

  constructor() {
    this.api = new HttpClientPayments();
  }

  getWallet = (
    signal?: AbortSignal
  ): Promise<AxiosResponse<GetWalletResponse>> => {
    return this.api.get(`v1/frenet/wallet`, signal);
  };

  postWalletDeposit = (
    value: number
  ): Promise<AxiosResponse<PostDepositResponse>> => {
    return this.api.post(`v1/frenet/wallet/deposit`, { value });
  };

  getExtract = ({
    page_number,
    page_size,
  }: {
    page_number: number;
    page_size: number;
  }): Promise<AxiosResponse<GetExtractResponse>> => {
    return this.api.get(
      `v1/frenet/wallet/demonstrative?page_size=${page_size}&page_number=${page_number}`
    );
  };
}
