// @flow
import { ReactElement, ReactFragment, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

function Login(): ReactElement | ReactElement<ReactFragment> {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <div />;
}

export default Login;
