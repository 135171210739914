import { ReactElement, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { BuildingBackground, Loader, Logo, Middle } from './styled';

import ImgLogo from '../../assets/images/logo/logo-white.png';
import Icon from '../../assets/icons';
import { theme } from '../../styles/theme';

function Progress(): ReactElement {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev: number) => {
        if (prev === 96) {
          clearInterval(interval);
        }
        return prev + 1;
      });
    }, 100);
    return () => {
      // Ao desmontar o componente
      clearInterval(interval);
    };
  }, []);

  return (
    <Loader>
      <Middle>
        <Logo src={ImgLogo} alt="Logo" />
        <ProgressBar
          now={progress}
          label={
            <Icon name="truck" color={theme.colors.lemonColor} size={34} />
          }
        />
        <p>Deixe a gente cuidar da sua encomenda!</p>
      </Middle>
      <BuildingBackground />
    </Loader>
  );
}

export default Progress;
