import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SellerInfoProps {
  email_validated: boolean;
  phone_validated: boolean;
  alredy_checked: boolean;
  loading: boolean;
}

export type TypeSellerInfo = {
  sellerInfo: SellerInfoProps;
};

const initialState: SellerInfoProps = {
  email_validated: false,
  phone_validated: false,
  alredy_checked: false,
  loading: true,
};

export const sellerInfoSlice = createSlice({
  name: 'sellerInfo',
  initialState,
  reducers: {
    updateSellerInfo: (state, action: PayloadAction<SellerInfoProps>) => {
      return action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateSellerInfoChecked: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        alredy_checked: action.payload,
      };
    },
  },
});

export const { updateSellerInfo, updateLoading, updateSellerInfoChecked } =
  sellerInfoSlice.actions;

export const selectSellerInfo = (state: TypeSellerInfo) => {
  return state.sellerInfo;
};

export default sellerInfoSlice.reducer;
