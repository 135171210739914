import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LimitsProps {
  max_weight: number;
  max_height: number;
  max_width: number;
  max_length: number;
  dimensions_sum: number;
}

interface CarrierDefaultProps {
  limits: LimitsProps;
  alredy_checked: boolean;
  loadingCarrier: boolean;
}

export type TypeCarrierDefault = {
  carrierDefault: CarrierDefaultProps;
};

const initialState: CarrierDefaultProps = {
  limits: {
    max_weight: 0,
    max_height: 0,
    max_width: 0,
    max_length: 0,
    dimensions_sum: 0,
  },
  alredy_checked: false,
  loadingCarrier: false,
};

export const carrierDefaultSlice = createSlice({
  name: 'carrierDefault',
  initialState,
  reducers: {
    updateCarrierDefaultSettings: (
      state,
      action: PayloadAction<CarrierDefaultProps>,
    ) => {
      return action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loadingCarrier: action.payload,
      };
    },
  },
});

export const { updateCarrierDefaultSettings, updateLoading } =
  carrierDefaultSlice.actions;

export const selectCarrierDefault = (state: TypeCarrierDefault) => {
  return state.carrierDefault;
};

export default carrierDefaultSlice.reducer;
