import { ReactElement } from 'react';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import ImgLogo from '../../assets/images/logo/logo1.png';
import {
  RowBottom,
  FooterContainer,
  ColLink,
  RowTop,
  Title,
  Line,
  LineContainer,
  Logo,
  LinkIconContainer,
  ButtonContainer,
} from './styled';
import Icon from '../../assets/icons';
import { theme } from '../../styles/theme';
import { RouteUrl } from '../../enum';
import LinkExternal from '../LinkExternal';
import { URL_ZENDESK } from '../../constants';

function Footer({
  manutenance = false,
}: {
  manutenance?: boolean;
}): ReactElement {
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <RowTop>
        <Col md={6} lg={3}>
          <div>
            <Logo src={ImgLogo} alt="Logo" />
          </div>

          <Title>Deixe a gente cuidar da sua encomenda!</Title>
          <LinkIconContainer>
            <a
              href="https://www.facebook.com/pontodrops"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="facebook"
                color={theme.colors.secondaryColor}
                size={20}
              />
            </a>
            <a
              href="https://instagram.com/pontodrops?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="instagram"
                color={theme.colors.secondaryColor}
                size={20}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/pontodrops/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="linkedin"
                color={theme.colors.secondaryColor}
                size={20}
              />
            </a>
          </LinkIconContainer>
        </Col>
        <Col
          className="py-sm-4 px-md-0 py-md-0 py-lg-0 px-lg-4 line-lg-vertical"
          md={6}
          lg={5}
        >
          <Title>Contato</Title>
          <LineContainer>
            <Icon name="at" color={theme.colors.secondaryColor} size={20} />
            <Line className="px-1">
              E-mail: <br />
              <span>atendimento@pontodrops.com.br</span>
            </Line>
          </LineContainer>
          <LineContainer>
            <Icon name="phone" color={theme.colors.secondaryColor} size={20} />
            <Line className="px-1">
              Telefone: <br /> <span>(11) 4040-3377</span>
            </Line>
          </LineContainer>
        </Col>
        <Col className="px-md-2 py-md-2" md={12} lg={4}>
          {!manutenance && (
            <ButtonContainer>
              <Button
                type="button"
                onClick={() => {
                  navigate(RouteUrl.SimulateDelivery);
                }}
              >
                Simular Entrega
                <Icon name="arrow-right" />
              </Button>
            </ButtonContainer>
          )}
        </Col>
      </RowTop>
      <RowBottom>
        <Col md={6}>2022 © .Drops - Todos os direitos reservados.</Col>
        <ColLink className="d-none d-md-flex" md={6}>
          <a href="/">Sobre</a>
          <LinkExternal url={URL_ZENDESK} target="_blank">
            Ajuda
          </LinkExternal>
        </ColLink>
      </RowBottom>
    </FooterContainer>
  );
}

export default Footer;
