import { Button } from 'react-bootstrap';
import styled, { css, DefaultTheme } from 'styled-components';

const getColor = (theme: DefaultTheme, disabled: boolean, variant: string) => {
  if (disabled) {
    return css`
      background-color: #6c757d !important;
      border: 1px solid #6c757d !important;
      color: #fff !important;
      :hover,
      :active,
      :focus {
        background-color: #6c757d !important;
        border: 1px solid #6c757d !important;
        color: #fff !important;
      }
    `;
  }
  if (variant === 'secondary') {
    return css`
      background-color: ${theme.colors.secondaryColor};
      border: 1px solid ${theme.colors.secondaryColor};
      :hover,
      :active,
      :focus {
        background-color: #039363;
        border: 1px solid #039363;
        color: #fff;
      }
    `;
  }
  if (variant === 'danger') {
    return css`
      background-color: ${theme.colors.dangerColor};
      border: 1px solid ${theme.colors.dangerColor};
      :hover,
      :active,
      :focus {
        background-color: #bb2d3b;
        border: 1px solid #bb2d3b;
        color: #fff;
      }
    `;
  }
  if (variant === 'orange') {
    return css`
      background-color: ${theme.colors.orangeColor};
      border: 1px solid ${theme.colors.orangeColor};
      :hover,
      :active,
      :focus {
        background-color: #e9ad04;
        border: 1px solid #e9ad04;
        color: #fff;
      }
    `;
  }
  if (variant === 'lemon') {
    return css`
      background-color: ${theme.colors.lemonColor};
      border: 1px solid ${theme.colors.lemonColor};
      :hover,
      :active,
      :focus {
        background-color: #aac714;
        border: 1px solid #aac714;
        color: #fff;
      }
    `;
  }
  if (variant === 'gray') {
    return css`
      background-color: ${theme.colors.greyColor};
      border: 1px solid ${theme.colors.greyColor};
      :hover,
      :active,
      :focus {
        background-color: #6c757d;
        border: 1px solid #6c757d;
        color: #fff;
      }
    `;
  }
  if (variant === 'transparent') {
    return css`
      color: ${theme.colors.primaryColor} !important;
      background-color: transparent;
      border: none;
    `;
  }
  return css`
    background-color: ${theme.colors.primaryColor};
    border: 1px solid ${theme.colors.primaryColor};
    :hover,
    :active,
    :focus {
      background-color: #00414c;
      border: 1px solid #00414c;
      color: #fff;
    }
  `;
};

export const Btn = styled(Button)`
  ${({ theme, variant = 'primary', disabled = false }) => css`
    ${getColor(theme, disabled, variant)}
    border-radius: 13px;
    color: #fff;
    width: 100%;
    font-size: ${theme.font.sizes.mediumX};
  `}
`;
