import { Environment, getCurrentApiEnv } from '../config/Environment';
import { RouteUrl } from '../enum';

const pagesChatNotAllowed = [RouteUrl.LabelHTML, RouteUrl.RegisterIntegration];

const canAddWebChat = () => {
  const url = window.location.pathname;
  return !pagesChatNotAllowed.find((pageUrl) => pageUrl === url);
};

const AddWebchatZendesk = () => {
  const existingScript = document.getElementById('ze-snippet');

  if (!existingScript && canAddWebChat()) {
    const script = document.createElement('script');
    script.src = Environment.baseUrl[getCurrentApiEnv()].webchatZendeskSrc;
    script.id = 'ze-snippet';
    document.body.appendChild(script);
  }
};

const RemoveWebchatZendesk = () => {
  const existingScript: HTMLElement | null =
    document.getElementById('ze-snippet');
  if (existingScript) {
    existingScript.remove();
  }
};

export { AddWebchatZendesk, RemoveWebchatZendesk };
