const CONST_LOCALSTORAGE = {
  CART_INFORMATION: 'CartInformation',
  PAYMENTS_ORDERS: 'PaymentsOrders',
  TOKEN: 'token',
  USER: 'userProfile',
  PRE_ORDERS: 'PreOrders',
  BATCH_ORDERS: 'BatchOrders',
};

const CONST_FILTERS = {
  ORDER_REQUESTS: 'FilterOrderRequests',
};

export { CONST_LOCALSTORAGE, CONST_FILTERS };
