/* eslint-disable react/destructuring-assignment */
import { Modal } from 'react-bootstrap';

import * as S from './styles';

export interface IAlertModal {
  type?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'orange'
    | 'lemon'
    | 'transparent';
  values: {
    title: string;
    text: string;
    text2?: string;
    textButton?: string;
  };
  visibleModal: () => void;
  show: boolean;
  size?: 'lg' | 'sm' | 'xl';
}

function AlertModal(props: IAlertModal) {
  return (
    <Modal show={props.show} size={props.size}>
      <S.HeaderModal>
        <S.TitleModal colors={props.type}>{props.values.title}</S.TitleModal>
        <S.CloseButton onClick={() => props.visibleModal()}>X</S.CloseButton>
      </S.HeaderModal>
      <S.BodyContainer>
        <S.TextModal>
          {props.values.text} <br />
          {props.values.text2}
        </S.TextModal>
        {props.values.textButton && (
          <S.ButtonContainer>
            <S.ButtonModal
              data-testid="button-modal"
              type="button"
              variant={props.type}
              onClick={() => props.visibleModal()}
            >
              {props.values.textButton}
            </S.ButtonModal>
          </S.ButtonContainer>
        )}
      </S.BodyContainer>
    </Modal>
  );
}

export default AlertModal;
