import { useAuth0 } from '@auth0/auth0-react';
import { UserLoginType } from '../enum';

type UserCredentials = {
  userId: any;
  email: string | undefined;
  emailVerified: boolean | undefined;
  role: string | undefined;
  document: string | undefined;
};

export default function useAuth0Credentials() {
  const { user } = useAuth0();

  const getId = (loginids: any[]) => {
    return loginids?.find((value) => value.type === UserLoginType.ClientId)?.id;
  };

  const newUser: UserCredentials = {
    userId: getId(user?.loginids),
    email: user?.email,
    emailVerified: user?.email_verified,
    role: user?.[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ],
    document:
      user?.['http://schemas.org/2021/10/identity/claims/documentnumber'],
  };

  return { ...newUser };
}
