export const URL_ZENDESK = 'https://ponto-drops.movidesk.com/form/6896/';
export const URL_NUVEMSHOP_LOGIN_DEV =
  'https://lojadrops2.lojavirtualnuvem.com.br/admin/apps/5265/authorize/';

export const URL_NUVENSHOP_IMAGE =
  'https://stpudoprd.blob.core.windows.net/public/assets/drops/integrations/nuvemshop.png';
export const URL_SEQUOIA_IMAGE =
  'https://stpudodev.blob.core.windows.net/public/assets/carriers/32d44e4a-4ff0-4de4-b67a-7ee923aeb6c0/logo.png';

export const SIGNALR = {
  PreOrderImportstatus: {
    EndPoint: 'pre-order-import-status-changed',
    Methods: {
      PreOrderImportStatusChanged: 'preOrderImportStatusChanged',
    },
  },
  NegotiateAddQuote: {
    EndPoint: 'add-quote',
    Methods: {
      addquotepreorder: 'addquotepreorder',
      addquoteerrorpreorder: 'addquoteerrorpreorder',
      addquoteimportedorderdraft: 'addquoteimportedorderdraft',
      addquoteerrorimportedorderdraft: 'addquoteerrorimportedorderdraft',
    },
  },
  ImportedOrderDraftStatusChanged: {
    EndPoint: 'imported-order-draft-status-changed',
    Methods: {
      importedorderdraftstatuschanged: 'importedorderdraftstatuschanged',
    },
  },
  PixPayment: {
    EndPoint: 'pix-payment',
    Methods: {
      paymentpix: 'paymentpix',
    },
  },
};

export const EMAIL_SUPPORT = 'atendimento@pontodrops.com.br';
