import HttpMaintenanceService from '../../config/HttpMaintenance';

export class MaintenanceService {
  api: HttpMaintenanceService;

  constructor() {
    this.api = new HttpMaintenanceService();
  }

  getMaintenanceConfig = () => {
    return this.api.service.get(`seller-is-in-maintenance`);
  };
}
