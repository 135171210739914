import HttpClientPayments from '../../config/HttpClientPayments';

export class ClientPaymentsService {
  api: HttpClientPayments;

  constructor() {
    this.api = new HttpClientPayments();
  }

  postCardClientPayments = (payload: any) => {
    return this.api.post(`v1/card-payments`, payload);
  };

  postPixClientPayments = (payload: any) => {
    return this.api.post(`v1/pix-payments`, payload);
  };

  getPaymentOrderDetails = (id: string) => {
    return this.api.get(`v1/client-payments/order/${id}`);
  };

  postClientPaymentsOrders = (payload: any) => {
    return this.api.post(`v1/client-payments/orders`, payload);
  };

  postShipmentsCheckout = (ids: string[]) => {
    return this.api.post(`v1/frenet/shipments/checkout`, {
      ids,
    });
  };

  cancelOrder = (id: string) => {
    return this.api.post(`v1/client-payments/orders/actions/cancel`, {
      order_id: id,
    });
  };

  getVoucher = (id: string) => {
    return this.api.get(`v1/vouchers/${id}`);
  };

  postValidateVoucher = (name: string, state: string, client_id: string) => {
    return this.api.post(`v1/vouchers/validate`, {
      name,
      state,
      client_id,
    });
  };
}
