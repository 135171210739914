import { AxiosResponse } from 'axios';
import HttpOrdersService from '../../config/HttpOrders';
import { LatestUpdatesRequest } from '../../pages/dashboard/@types';
import {
  GetOrdersDataResponse,
  GetOrderTotalizersDataResponse,
  GetSimulateDataResponse,
  ValidateInvoice,
  OrderQuoteProps,
  GetValidateInvoiceDataResponse,
  PostReconciliationResponse,
} from './orders.types';

export class OrderService {
  api: HttpOrdersService;

  constructor() {
    this.api = new HttpOrdersService();
  }

  postReconciliation = ({
    page_number,
    page_size,
    sort_column,
    sort_order,
    filter,
  }: {
    page_number: number;
    page_size: number;
    sort_column: number;
    sort_order: number;
    filter?: any;
  }): Promise<AxiosResponse<PostReconciliationResponse>> => {
    return this.api.post(
      `v1/orders/dimension_reconciliation?page_size=${page_size}&page_number=${page_number}&sort_column=${sort_column}&sort_order=${sort_order}`,
      {
        ...filter,
      }
    );
  };

  getSimulate = (payload: any): Promise<GetSimulateDataResponse> => {
    return this.api.post(`v1/orders/simulate/delivery`, payload);
  };

  // FRENET SIMULATE
  getSimulateFrent = (payload: any): Promise<GetSimulateDataResponse> => {
    return this.api.post(`v1/frenet/quotes`, payload);
  };

  getOrder = (id: string) => {
    return this.api.get(`v1/orders/${id}`);
  };

  getOrdersByIds = (payload: { ids: string[] }) => {
    return this.api.post(`v1/orders/ids`, payload);
  };

  getQuoteScenario1SignalR = (payload: OrderQuoteProps) => {
    return this.api.post(`v1/orders/scenarios/1/quotes`, payload);
  };

  getOrdersDrafts = () => {
    return this.api.get('v1/orders/drafts');
  };

  getOrdersFrenet = (payload: { page_number: number; page_size: number }) => {
    return this.api.post('v1/frenet/shipments/list', payload);
  };

  deleteOrders = (Ids: string[]) => {
    return this.api.delete('v1/frenet/shipments/batch', {
      Ids,
    });
  };

  cancelOrder = (id: string) => {
    return this.api.post('v1/orders/actions/order_and_payment_canceled', {
      order: id,
    });
  };

  cancelOrderPix = (id: string) => {
    return this.api.post('v1/orders/actions/order_and_pix_payment_canceled', {
      order: id,
    });
  };

  getOrders = (
    filter: any,
    page_number: number,
    page_size: number
  ): Promise<GetOrdersDataResponse> => {
    return this.api.post(
      `v1/orders/search?page_number=${page_number}&page_size=${page_size}`,
      filter
    );
  };

  getOrderTotalizers = (
    id: string
  ): Promise<GetOrderTotalizersDataResponse> => {
    return this.api.get(`v1/orders/client/counters?client_id=${id}`);
  };

  getOrderCounters = (id: string): Promise<GetOrderTotalizersDataResponse> => {
    return this.api.get(
      `v1/orders/client/external_id/counters?client_id=${id}`
    );
  };

  postOrder = (payload: any) => {
    return this.api.post('v1/orders', payload);
  };

  getLatestUpdates = (
    page_number: number,
    page_size: number
  ): Promise<LatestUpdatesRequest> => {
    return this.api.post(
      `v1/orders/client/latest_updates?page_number=${page_number}&page_size=${page_size}`,
      {}
    );
  };

  getLabelHTML = (trackingNumber: string) => {
    return this.api.get(`v1/orders/${trackingNumber}/label_raw_html`);
  };

  postLabelHTML = (payload: any) => {
    return this.api.post(`v1/orders/label_raw_html`, payload);
  };

  // PreOrders

  getPreOrders = (filter: any, page_number: number, page_size: number) => {
    const auxFilter = {
      ...filter,
      pagination: {
        page_number,
        page_size,
      },
    };
    return this.api.post(`v1/pre-orders/search`, auxFilter);
  };

  getValidateInvoice = (
    payload: ValidateInvoice
  ): Promise<GetValidateInvoiceDataResponse> => {
    return this.api.post(`v1/orders/validate_invoice`, payload);
  };

  // Voucher
  setVoucher = (payload: any) => {
    return this.api.post(`v1/orders/voucher`, payload);
  };

  removeVoucher = (payload: any) => {
    return this.api.post(`v1/orders/voucher/remove`, payload);
  };

  // Frenet
  getFrenetOrderByExternalId = (id: string) => {
    return this.api.get(`v1/orders/external_id/${id}`);
  };

  frenetShipmentCancel = (payload: any) => {
    return this.api.post(`v1/frenet/shipments/batch/cancel`, payload);
  };

  frenetShipmentLabel = (payload: any) => {
    return this.api.getFileWithParams(
      `v1/frenet/shipments/batch/label/generate`,
      payload
    );
  };

  frenetShipmentOrder = (payload: any) => {
    return this.api.post(`v1/frenet/shipments`, payload);
  };
}
