/* eslint-disable react/destructuring-assignment */
import { Modal } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import * as S from './styles';
import { RouteUrl } from '../../../enum';
import { EMAIL_SUPPORT } from '../../../constants';

export interface INoWalletModal {
  visibleModal: () => void;
  show: boolean;
  size?: 'lg' | 'sm' | 'xl';
}

function NoWalletModal(props: INoWalletModal) {
  return (
    <Modal show={props.show} size={props.size} style={{ zIndex: 10000 }}>
      <S.HeaderModal>
        <S.TitleModal>Como aumentar o limite de etiquetas</S.TitleModal>
        <S.CloseButton onClick={() => props.visibleModal()}>X</S.CloseButton>
      </S.HeaderModal>
      <S.BodyContainer>
        <S.TextContainer>
          <p>
            Para aumentar o limite de etiquetas é preciso comprovar alguns dados
            pessoais do responsável pela conta.
          </p>
          <p>Abaixo, segue os documentos solicitados.</p>
          <ul>
            <li>• Copia do RG ou CNH</li>
            <li>• Copia do CPF</li>
            <li>• Copia do Contrato Social ou MEI</li>
            <li>• Copia do comprovante de endereço</li>
            <li>
              • Selfie com RG ou GNH e a data escrita em papel a próprio punho
            </li>
          </ul>
          <p>
            Após reunir as informações, envie para o e-mail
            <a href={`mailto:${EMAIL_SUPPORT}`}> {EMAIL_SUPPORT}</a>
          </p>
          <p>
            Em casos de cadastro por pessoa jurídica, é necessário enviar os
            documentos de um dos sócios da empresa
          </p>
          <p>
            Você pode ler mais sobre isso neste artigo que preparamos para você.
            <Link to={RouteUrl.FAQ}> Saiba mais!</Link>
          </p>
        </S.TextContainer>
      </S.BodyContainer>
    </Modal>
  );
}

export default NoWalletModal;
