import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

type LinkExternalProps = {
  url?: string;
  target?: string;
  children: React.ReactNode;
};

function LinkExternal({
  url,
  target,
  children,
}: LinkExternalProps): ReactElement {
  const location = useLocation();
  return (
    <a
      href={url || '#'}
      target={target}
      className={`${url === location.pathname ? ' active-nav' : ''}`}
    >
      {children}
    </a>
  );
}

export default LinkExternal;
