import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

const FooterContainer = styled.footer`
  font-size: 14px;
  color: ${theme.colors.primaryColor};
`;

const RowTop = styled(Row)`
  background-color: #ccecde;
  padding: 1.4em 1.6em;
`;

const RowBottom = styled(Row)`
  background-color: #ccd8d8;
  padding: 1em;
`;

const Title = styled.p`
  color: #8e9ca8;
  line-height: 1.3;
  font-weight: ${theme.font.fontWeight.semiBold};
  margin-bottom: 0.4rem;
`;

const Line = styled.p`
  margin-bottom: 6px;
  line-height: 1.4em;
  font-weight: ${theme.font.fontWeight.medium};
  span {
    font-weight: ${theme.font.fontWeight.normal};
  }
`;

const LineContainer = styled.div`
  display: flex;
`;
const ColLink = styled(Col)`
  display: flex;
  justify-content: flex-end;
  gap: 22px;
  a {
    text-decoration: none;
    color: ${theme.colors.primaryColor};
    &:hover,
    &:focus {
      color: ${theme.colors.primaryColor};
    }
  }
`;

const LinkIconContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${theme.colors.primaryColor};
  &:hover,
  &:focus {
    color: ${theme.colors.primaryColor};
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 8em;
  height: auto;
  margin: 10px 0;
`;

const ButtonContainer = styled.div`
  max-width: 200px;
  @media (min-width: 992px) {
    margin-left: auto;
  }
`;

export {
  FooterContainer,
  RowTop,
  RowBottom,
  Title,
  Line,
  LineContainer,
  ColLink,
  LinkIconContainer,
  Link,
  Logo,
  ButtonContainer,
};
