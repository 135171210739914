export const theme = {
  colors: {
    primaryColor: '#004D59',
    secondaryColor: '#03AD75',
    orangeColor: '#FFBC00',
    lemonColor: '#D2FA00',
    dangerColor: '#E87960',
    backgroundPageColor: '#FFFBF8',
    greyColor: '#8E9CA8',
    lightGreenColor: '#CCECDE',
  },
  font: {
    family: {
      default: 'Saira, sans-serif',
    },
    sizes: {
      small: '10px',
      medium: '13px',
      mediumX: '14px',
      mediumXX: '16px',
      large: '24px',
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
} as const;
