import HttpRegistration from '../../config/HttpRegistration';

export type PreRegistrationDataProps = {
  name: string;
  email_address: string;
  document: string;
  password_credential: string | null;
  password_confirmation_credential: string | null;
  existent_user?: boolean;
};

export class RegistrationService {
  api: HttpRegistration;

  constructor() {
    this.api = new HttpRegistration();
  }

  clientRegistration = (payload: any) => {
    return this.api.post(`func-register-client`, payload);
  };

  clientPreRegistration = (payload: PreRegistrationDataProps) => {
    return this.api.post(`func-pre-register-client`, payload);
  };

  checkExistentUser = (document: string) => {
    return this.api.get(`user/${document}`);
  };
}
