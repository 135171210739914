import { ReactElement } from 'react';
import { Btn } from './styled';

type ButtonProps = {
  children: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'orange'
    | 'lemon'
    | 'transparent'
    | 'gray';
  onClick?: () => void;
  customStyles?: any;
};

function Button({
  children,
  variant = 'primary',
  type = 'submit',
  disabled = false,
  onClick,
  customStyles,
}: ButtonProps): ReactElement {
  return (
    <Btn
      style={{ ...customStyles }}
      variant={variant}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Btn>
  );
}

export default Button;
