import axios, { AxiosInstance } from 'axios';
import { UserLoginType } from '../enum';
import { Environment, getCurrentApiEnv } from './Environment';
import { getToken } from './GetToken';

export default class HttpClientService {
  service: AxiosInstance;

  constructor() {
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'X-User-Login-Type': UserLoginType.ClientId,
      },
    });

    this.service.defaults.headers.common.Authorization = getToken() ?? '';
  }

  get(path: string) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlApi + path;
    return this.service.get(url);
  }

  put(path: string, payload: any) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlApi + path;
    return this.service.put(url, payload);
  }

  post(path: string, payload: any) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlApi + path;
    return this.service.post(url, payload);
  }

  patch(path: string, payload?: any) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlApi + path;
    return this.service.patch(url, payload);
  }

  delete(path: string) {
    const url = Environment.baseUrl[getCurrentApiEnv()].urlApi + path;
    return this.service.delete(url);
  }
}
