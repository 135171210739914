import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetWalletResponse } from '../service/shared/wallet.types';

export type TypeWallet = {
  wallet: GetWalletResponse;
};

const initialState: GetWalletResponse = {
  balance: 0,
  bonus_balance: 0,
  blocked_balance: 0,
  available_balance_for_deposit: 0,
  label_limit: 0,
  wallet_limit: 0,
  labels_used: 0,
  loading: false,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    updateWallet: (state, action: PayloadAction<GetWalletResponse>) => {
      return action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const { updateWallet, updateLoading } = walletSlice.actions;

export const selectWallet = (state: TypeWallet) => {
  return state.wallet;
};

export default walletSlice.reducer;
