import HttpOrdersService from '../../config/HttpOrders';
import {
  GetListOrdersBatchDataResponse,
  GetOrdersBatchDataResponse,
} from './ordersBatch.types';

export class OrderBatchService {
  api: HttpOrdersService;

  constructor() {
    this.api = new HttpOrdersService();
  }

  getListOrderDrafts = (
    page_number: number,
    page_size: number
  ): Promise<GetListOrdersBatchDataResponse> => {
    return this.api.post(
      `v1/imported-order-drafts/search?page_number=${page_number}&page_size=${page_size}`,
      {}
    );
  };

  getOrderDraft = (id: string): Promise<GetOrdersBatchDataResponse> => {
    return this.api.get(`v1/imported-order-drafts/${id}`);
  };

  putOrderDraft = (payload: any) => {
    return this.api.put(`v1/imported-order-drafts/${payload.id}`, payload);
  };

  deleteOrderDraft = (id: string) => {
    return this.api.patch(`v1/imported-order-drafts/${id}/actions/cancel`);
  };

  downloadFile = () => {
    return this.api.getFile(`v1/imported-order-drafts/import_template`);
  };

  uploadFile = (file: any) => {
    return this.api.postFile(`v1/imported-order-drafts/import`, file);
  };

  postOrders = (payload: any) => {
    return this.api.post(
      `v1/imported-order-drafts/actions/batch_convert_to_order`,
      payload
    );
  };
}
