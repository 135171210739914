import axios, { AxiosResponse } from 'axios';
import { Environment, getCurrentApiEnv } from '../../config/Environment';
import { getToken } from '../../config/GetToken';

export class HttpCnpjService {
  service: any;

  constructor() {
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.service.defaults.headers.common.Authorization = getToken();
  }

  getCnpj(cnpj: string): Promise<GetCnpjResponse> {
    this.service.defaults.headers = {
      ...this.service.defaults.headers,
      'x-functions-key': Environment.baseUrl[getCurrentApiEnv()].funcCnpj.code,
    };

    const { url } = Environment.baseUrl[getCurrentApiEnv()].funcCnpj;
    return this.service.get(`${url}get-cnpj?document=${cnpj}`);
  }
}

export type GetCnpjResponse = AxiosResponse<InformationCnpjData>;

type Socios = {
  cpf_cnpj_socio: string;
  nome: string;
  tipo: string;
  data_entrada: string;
  cpf_representante_legal: string;
  nome_representante: string | null;
  faixa_etaria: string;
  atualizado_em: string;
  pais_id: string;
  qualificacao_socio: { id: number; descricao: string };
  qualificacao_representante: null;
};

type Atividade = {
  id: string;
  secao: string;
  divisao: string;
  grupo: string;
  classe: string;
  subclasse: string;
  descricao: string;
};

type InscricaoEstadual = {
  inscricao_estadual: string;
  ativo: boolean;
  atualizado_em: string;
  estado: { id: number; nome: string; sigla: string; ibge_id: number };
};

export type InformationCnpjData = {
  cnpj_raiz: string;
  razao_social: string;
  capital_social: string;
  responsavel_federativo: string;
  atualizado_em: string;
  porte: { id: string; descricao: string };
  natureza_juridica: {
    id: string;
    descricao: string;
  };
  qualificacao_do_responsavel: {
    id: number;
    descricao: string;
  };
  socios: Socios[];
  simples: {
    simples: string;
    data_opcao_simples: string;
    data_exclusao_simples: string;
    mei: string;
    data_opcao_mei: string | null;
    data_exclusao_mei: string | null;
    atualizado_em: string;
  };
  estabelecimento: {
    cnpj: string;
    atividades_secundarias: Atividade[];
    cnpj_raiz: string;
    cnpj_ordem: string;
    cnpj_digito_verificador: string;
    tipo: string;
    nome_fantasia: string;
    situacao_cadastral: string;
    data_situacao_cadastral: string;
    data_inicio_atividade: string;
    nome_cidade_exterior: string | null;
    tipo_logradouro: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cep: string;
    ddd1: string;
    telefone1: string;
    ddd2: string | null;
    telefone2: string | null;
    ddd_fax: string | null;
    fax: string | null;
    email: string;
    situacao_especial: string | null;
    data_situacao_especial: string | null;
    atividade_principal: Atividade;
    pais: {
      id: string;
      iso2: string;
      iso3: string;
      nome: string;
      comex_id: string;
    };
    estado: { id: number; nome: string; sigla: string; ibge_id: number };
    cidade: { id: number; nome: string; ibge_id: number; siafi_id: string };
    motivo_situacao_cadastral: string | null;
    inscricoes_estaduais: InscricaoEstadual[];
  };
};
