import axios, { type AxiosInstance } from 'axios';
import { Environment, getCurrentApiEnv } from './Environment';

export default class HttpMaintenanceService {
  service: AxiosInstance;

  constructor() {
    this.service = axios.create({
      baseURL: Environment.baseUrl[getCurrentApiEnv()].maintenance.url,
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': Environment.baseUrl[getCurrentApiEnv()].maintenance.apiKey,
        'x-functions-key':
          Environment.baseUrl[getCurrentApiEnv()].maintenance.apiKey,
      },
    });
  }
}
