import styled, { css } from 'styled-components';
import { Col } from 'react-bootstrap';

export const HiddenMobile = styled(Col)`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const HiddenLg = styled(Col)`
  @media (min-width: 1023px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: inline;
  padding: 15px 30px;
  background-color: #fff;
  min-height: 70px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1001;
  box-shadow: 1px 1px 0.2rem rgba(0, 0, 0, 0.2);

  @media (max-width: 1023px) {
    padding: 15px;
  }
`;

export const Logo = styled.img`
  width: 8em;
  height: auto;
  margin: 10px 0;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const LogoMobile = styled.img`
  width: 8em;
  height: auto;
  margin: 10px 0;
`;

export const Hamburger = styled.div`
  position: absolute;
  top: 1.4em;
  left: 11em;

  @media (max-width: 1023px) {
    left: 0;
  }
`;

export const WalletContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 24px;
`;

export const WalletContent = styled.div`
  display: flex;
  height: 100%;
  gap: 8px;
`;

export const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const TagContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

type CartProps = {
  hideMobile?: boolean;
};

export const Cart = styled.div<CartProps>`
  ${({ theme, hideMobile }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 5px;
    cursor: pointer;
    a {
      color: ${theme.colors.primaryColor};
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.fontWeight.semiBold};
      :hover {
        color: ${theme.colors.primaryColor};
      }
    }

    @media (max-width: 1023px) {
      display: ${hideMobile ? 'none' : 'flex'};
      position: absolute;
      right: 1rem;
    }
    @media (max-width: 1200px) {
      margin-right: 0;
    }
  `}
`;

export const SpanCart = styled.span`
  ${({ theme }) => css`
    color: #8e9ca8;
    font-size: ${theme.font.sizes.small};
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 20em;
  float: right;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const Logout = styled.div`
  width: 30px;
  margin-left: 20px;
  cursor: pointer;
`;
